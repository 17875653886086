define("discourse/plugins/discourse-newsletter-integration/discourse/connectors/user-preferences-emails/subscribe-newsletter-section", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscribeNewsletterSection extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showSubscribeSection() {
      return this.site.newsletter_integration_plugin_configured;
    }
  }
  _exports.default = SubscribeNewsletterSection;
});