define("discourse/plugins/discourse-newsletter-integration/discourse/templates/connectors/top-notices/newsletter-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showBanner}}
    <aside class="newsletter-subscription-banner">
      <div class="banner-text">
        {{#if this.subscribed}}
          <h3>{{i18n "discourse_newsletter_integration.banner.thank_you"}}
            {{emoji "tada"}}</h3>
          <p class="banner-description">
            {{html-safe
              (i18n
                "discourse_newsletter_integration.banner.added_to_newsletter"
                preferencesUrl=(get-url "/my/preferences/emails")
              )
            }}
          </p>
        {{else}}
          <h2>{{i18n "discourse_newsletter_integration.banner.heading"}}
            {{emoji "love_letter"}}</h2>
          <p class="banner-description">
            {{i18n "discourse_newsletter_integration.banner.description"}}
          </p>
        {{/if}}
      </div>
      <div class="banner-controls">
        <DButton @icon="xmark" @action={{action "dismiss"}} class="close-btn" />
        {{#unless this.subscribed}}
          <DButton
            @label="discourse_newsletter_integration.banner.subscribe"
            @action={{action "subscribe"}}
            @disabled={{this.disableControls}}
            class="btn-primary subscribe-btn"
          />
        {{/unless}}
      </div>
    </aside>
  {{/if}}
  */
  {
    "id": "Q9RHoEHb",
    "block": "[[[41,[30,0,[\"showBanner\"]],[[[1,\"  \"],[10,\"aside\"],[14,0,\"newsletter-subscription-banner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"banner-text\"],[12],[1,\"\\n\"],[41,[30,0,[\"subscribed\"]],[[[1,\"        \"],[10,\"h3\"],[12],[1,[28,[35,4],[\"discourse_newsletter_integration.banner.thank_you\"],null]],[1,\"\\n          \"],[1,[28,[35,5],[\"tada\"],null]],[13],[1,\"\\n        \"],[10,2],[14,0,\"banner-description\"],[12],[1,\"\\n          \"],[1,[28,[35,7],[[28,[37,4],[\"discourse_newsletter_integration.banner.added_to_newsletter\"],[[\"preferencesUrl\"],[[28,[37,8],[\"/my/preferences/emails\"],null]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"h2\"],[12],[1,[28,[35,4],[\"discourse_newsletter_integration.banner.heading\"],null]],[1,\"\\n          \"],[1,[28,[35,5],[\"love_letter\"],null]],[13],[1,\"\\n        \"],[10,2],[14,0,\"banner-description\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"discourse_newsletter_integration.banner.description\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"banner-controls\"],[12],[1,\"\\n      \"],[8,[39,10],[[24,0,\"close-btn\"]],[[\"@icon\",\"@action\"],[\"xmark\",[28,[37,11],[[30,0],\"dismiss\"],null]]],null],[1,\"\\n\"],[41,[51,[30,0,[\"subscribed\"]]],[[[1,\"        \"],[8,[39,10],[[24,0,\"btn-primary subscribe-btn\"]],[[\"@label\",\"@action\",\"@disabled\"],[\"discourse_newsletter_integration.banner.subscribe\",[28,[37,11],[[30,0],\"subscribe\"],null],[30,0,[\"disableControls\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"aside\",\"div\",\"h3\",\"i18n\",\"emoji\",\"p\",\"html-safe\",\"get-url\",\"h2\",\"d-button\",\"action\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-newsletter-integration/discourse/templates/connectors/top-notices/newsletter-banner.hbs",
    "isStrictMode": false
  });
});